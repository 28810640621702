import React from 'react'
import { useHistory } from 'react-router';
import logoPetifa from '../images/LogoPefifa.png'

const Menu = ({ setShow }) => {
    const history = useHistory()
    return (
        <div className="menu-container">
            <div>
                <img className="menu-container-image" src={logoPetifa} alt="petifa´s logo" />
                <span onClick={() => setShow(false)}>
                <i className="fa fa-times closeIcon" /> 
                </span>
            </div>
            <ul className="menu-container-list">
                <li> <a onClick={() => history.push('/')}> Home </a> </li>
                <li> <a onClick={() => history.push('/aboutUs')}> About Us </a> </li>
                <li> <a onClick={() => history.push('/services')}> Services </a> </li>
                <li> <a onClick={() => history.push('/contactus')}> Contact Us </a> </li>
            </ul>
        </div>
    )
}

export default Menu
