import React, {useState} from 'react'
import { useHistory } from 'react-router';
import Menu from "./Menu"

const Navbar = () => {
    const history = useHistory()
    const [show, setShow] = useState(false)

    if (show) {
        return <Menu setShow={setShow} />
    }
    
    return (
        
        <div className="navbar-container">
            <div className="navbar-icons">
                <div>
                    <a href="https://www.linkedin.com/in/isabel-farinha-15ab2121/" target="blank">
                        <i className="fab fa-linkedin-in fa-2x"/>
                    </a>
                    <a href="#contactUs">
                        <i className="fas fa-2x fa-envelope"/>
                    </a>
                </div>
            </div>

            <div className="web-sections">
                <ul className="web-sections-list">
                <li><a onClick={() => history.push('/')}> Home</a></li>
                <li><a onClick={() => history.push('/aboutUs')}> About Us</a></li>
                <li><a onClick={() => history.push('/services')}> Services</a></li>
                <li> <a onClick={() => history.push('/contactus')}> Contact Us</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar
