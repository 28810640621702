import emailjs from 'emailjs-com'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import Menu from './Menu'

const serviceID = 'service_256irbr'
const templateID = 'template_l6lcz6h'
const publicKey = 'hpyI-1gytCBROQ0ox' // public key

/*
1. To configure the emailjs, you need to create an account on emailjs.com
2. After creating the account, you need to create a service and a template.
3. To create a service, click on the "Email Services" tab on the left side of the screen, and add a new email service with the following details:
	- Service Type: SMTP
	- Service Name: Petifa
	- Service ID: generated by emailjs
	- Service Host: mail.petifa.pt
	- Service Port: 465
	- User: email address
	- Password: password
*/

const ContactUs = () => {
	const [show, setShow] = useState(false)
	const [sendingEmail, setSendingEmail] = useState(false)
	const [emailSent, setEmailSent] = useState(false)

	if (show) {
		return <Menu setShow={setShow} />
	}

	const sendEmail = (e) => {
		e.preventDefault()
		setSendingEmail(true)
		emailjs.sendForm(serviceID, templateID, e.target, publicKey).then(
			(result) => {
				setSendingEmail(false)
				setEmailSent(true)
				toast.success('Email Sent')
				e.target.reset()
				console.log('Email Sent', result.text)
			},
			(error) => {
				setSendingEmail(false)
				toast.error('Error Sending Email')
				e.target.reset()
				console.log('Error Sending Email', error.text)
			}
		)
	}

	return (
		<>
			<div className="contactUs-container animate__animated animate__fadeIn">
				<h3 className="extraTitleContactUs">Do you have any questions?</h3>
				<div className="responsive-form">
					<i className="fas fa-envelope-open formIcon"></i>
					<form onSubmit={sendEmail}>
						<div className="contactUs-form">
							<div className="contactUs-title">
								<h2 id="contactUs">Contact Us</h2>
							</div>
							<div className="contactUs-inputContainer">
								<input
									className="formElement input"
									placeholder="Name"
									name="from_name"
									type="text"
									required
								/>
							</div>
							<div className="contactUs-inputContainer">
								<input
									className="formElement input"
									placeholder="Email Address"
									name="from_email"
									type="email"
									required
								/>
							</div>
							<div className="contactUs-inputContainer">
								<input
									className="formElement input"
									placeholder="Subject"
									name="subject"
									type="text"
									required
								/>
							</div>
							<div className="contactUs-inputContainer">
								<textarea
									className="formElement textArea"
									placeholder="Your Message"
									name="message"
									required
								/>
							</div>
							<div className="contactUs-inputContainer">
								<button type="submit" className="formElement submitBtn">
									{sendingEmail && (
										<i className="fas fa-spinner fa-spin" style={{ marginRight: 10 }} />
									)}
									{!sendingEmail && emailSent && (
										<i className="fas fa-check" style={{ marginRight: 10 }} />
									)}
									Submit
								</button>
							</div>
						</div>
					</form>
				</div>
				<div className="contactDetails">
					<h4>Contacts</h4>
					<p className="details companyName">Petifa, Unipessoal Lda.</p>
					<p className="details address">Lisboa - Portugal</p>
					<p className="details telephoneNumber">Tel : (+351) 215 959 320</p>
					<p className="details email">Email : petifa@petifa.pt</p>
				</div>
			</div>
		</>
	)
}

export default ContactUs
