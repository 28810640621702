import 'animate.css'
import React, { useState } from 'react'
import Menu from './Menu'

import AboutUsPic from '../images/AboutUsPic_2.jpg'

const AboutUs = () => {
	const [show, setShow] = useState(false)
	// const history = useHistory();

	if (show) {
		return <Menu setShow={setShow} />
	}

	return (
		<>
			<div className="aboutUs-container">
				<div className="aboutUs-title">
					<h2>About us</h2>
				</div>
				<div className="aboutUs-content animate__animated animate__fadeIn">
					<div className="aboutUs-content-image">
						<img className="image" alt="about us petifa" src={AboutUsPic} />
					</div>
					<div className="aboutUs-content-text">
						<span className="desktopTitle">Hi!</span>
						<p>
							I am <span className="bold">Isabel Farinha</span>, I’m Portuguese and have a master
							degree in Pharmaceutical Sciences, branch of Chemical-Biological Analysis from the
							University of Lisbon. After several years of experience in multinational companies
							related with clinical diagnostic, health ingredients and the pet food markets, in 2015
							I decided to create my own technical consultancy Petifa.
						</p>

						<p>
							With technical and marketing backgrounds, in Petifa we support our customers in the
							development of new products and/or improvement of their actual portfolio.
						</p>

						<p>
							For more than 10 years we have been working with petfood manufacturers and rendering
							companies, helping them to achieve the best oxidative stability of their products,
							solving oxidation issues and giving support in the change from synthetic to natural
							antioxidants. We also represent a wide variety of products for the pet food,
							aquaculture and rendering companies.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default AboutUs
