import React from 'react'


const Services_card = ({text, icon, description}) => {
    return (
        <div className="services_card">
            <div className="services_card__icon"><i className={ icon }></i></div>
            <h2 className="services_card__text">{ text }</h2>
            <p className="services_card__description">{ description }</p>
        </div>
    )
}

export default Services_card;