import React, { useState } from 'react'
import Menu from "./Menu"
import ServicesCard from "./page_components/Services_card";

const Services = () => {

    const [show, setShow] = useState(false)
    const [services] = useState([
        {
            text: "Technical support in oxidative stability.",
            icon: "fas fa-magic",
            description:"Oxidation has always been a big challenge for the pet food and rendering industries. It is extremely important to choose the correct antioxidant, use it in the most adequate dose at the right place, in order to delay oxidation and increase shelf life in pet food, meat meals and fats. We can help you making the right choice."
        },
        {
            text: "R&D and product development.",
            icon: "fas fa-flask",
            description:"Pet food is no longer just a simple “feed for dogs and cats”. We can see many different formulas, even in the economic segment, adapted to specific needs, ages, sizes, and breeds of the pets. In Petifa we work with one of our partners to give you all the support in the development of new formulas."
        },
        {
            text: "Palatability tests with “in home” panel.",
            icon: "fas fa-house-user",
            description:"Palatability tests are fundamental in the development of new products, to compare your products with the competition or to choose between different palatants.In Petifa we work with a Portuguese company that makes cat and dog palatability trials, with many different options. Ask us about it."
        },
        {
            text: "Palatability enhancement.",
            icon: "fas fa-bone",
            description:"Once you produce a stable kibble, a good palatability makes all the difference when it comes to the animal’s choice. More palatable doesn’t necessarily mean more quantity or more cost.Petifa works with one of our partners to give you all the recommendations for the enhancement palatability."
        },
        {
            text: "Marketing support.",
            icon: "fas fa-bullseye",
            description:"Marketing’s importance in pet food has been increasing in the last years. Pet owners are more demanding, more informed, and want to give the best to their pets. With our experience in pet food markets worldwide, in Petifa we can help you with your marketing campaigns, product positioning, market studies in almost all the countries in the world."
        },
        {
            text: "Cost reductions.",
            icon: "fas fa-chart-line",
            description:"Quality rules but price continues to be a crucial element when creating a new formula. Better doesn’t necessarily mean more expensive. Choosing between different types of ingredients and providers can give you a cost reduction without loosing quality. Together with our different partners, we can support you in your search."
        }

    ])

    if (show) {
        return <Menu setShow={setShow} />
    }

    return (
        <>
        <div className="services-container">
            <div className="services-titles">
                <h2 className="serviceTitle">Services</h2>
                <p className="serviceDescription">Petifa offers different services and expertise, including:</p>
            </div>
            <div className="services-content animate__animated animate__fadeIn">
                {services.map((x) =>(
                    <ServicesCard key={x.text} text={x.text} icon={x.icon} description={x.description} />
                )
                )}
            </div>
        </div>
        </>
    )
}

export default Services;