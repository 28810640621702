import React from 'react'

import logoPetifa from '../images/LogoPefifa.png'
import Navbar from './Navbar'
import AboutUs from './AboutUs'
import Services from './Services'
import ContactUs from './ContactUs'

const Home = () => {
    return (
        <>
            <Navbar />
            <div className="home-container">
                <div className="main-logo-container">
                    <img className="main-logo-container-image" src={logoPetifa} alt="petifa´s logo" />
                    <h2 className='main-logo-container-subtitle'>Products and Services For Pet Food</h2>
                </div>
                <h2 className='desktopSubtitle' style={{display:'none'}}>Products and Services For Pet Food</h2>
                <div className="icon-scroll"></div>
                <div className="aboutUs-component" id="aboutUs">
                    <AboutUs/>
                </div>
                <div className="services-component">
                    <Services/>
                </div>
                <div className="contactUs-component">
                    <ContactUs/>
                </div>

            </div>
        </>
    )
}

export default Home;