import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './stylesheets/App.scss'

//Import Components
import AboutUs from './components/AboutUs'
import ContactUs from './components/ContactUs'
import Home from './components/Home'
import Services from './components/Services'

function App() {
	return (
		<div className="App">
			<Toaster
				position="top-right"
				toastOptions={{
					// Define default options
					duration: 5000,
				}}
			/>
			<Router>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route path="/aboutUs">
						<AboutUs />
					</Route>
					<Route path="/services">
						<Services />
					</Route>
					<Route path="/contactus">
						<ContactUs />
					</Route>
				</Switch>
			</Router>
		</div>
	)
}

export default App
